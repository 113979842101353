import {
  assertInInjectionContext,
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
  Provider,
} from '@angular/core';
import { LgsConfiguration, LgsFeatures } from './lgs-configuration';

export const environment = getConfig();

export function injectLgsConfiguration(): LgsConfiguration {
  assertInInjectionContext(injectLgsConfiguration);
  return inject(APPLICATION_CONFIGURATION);
}

export function provideLgsConfiguration(): EnvironmentProviders {
  const providers: Provider[] = [
    { provide: APPLICATION_CONFIGURATION, useFactory: getLgsConfiguration },
  ];

  return makeEnvironmentProviders(providers);
}

function getConfig(): {
  apiURL: string;
  auth0: { domain: string; clientId: string; audience: string };
  university: 'undj' | 'uvt' | 'enstp' | 'staging';
  features: LgsFeatures;
} {
  switch (window.location.host) {
    case 'app.universite-ndjamena.td':
    case 'lgs.universite-ndjamena.td':
      return {
        apiURL: 'https://undj.welma-education.com/api',
        auth0: {
          domain: 'universite-ndjamena.eu.auth0.com',
          clientId: 'AewJdKGzFYxnZfd5cBy8ySoff0oX9JWU',
          audience: 'https://api.universite-ndjamena.td',
        },
        university: 'undj',
        features: {
          card: 'v1',
          certificate: 'v1',
        },
      };
    case 'lgs.universite-virtuelle.td':
      return {
        apiURL: 'https://uvt.welma-education.app/api',
        auth0: {
          domain: 'universite-virtuelle.eu.auth0.com',
          clientId: 'CNx6RUN7bumrScAQgiDx6gR1eVXG24Ge',
          audience: 'https://api.universite-virtuelle.td',
        },
        university: 'uvt',
        features: {
          card: 'v2',
          certificate: 'v2',
        },
      };
    case 'lgs.welma.app':
    case 'www.welma.app':
      return {
        apiURL: 'https://staging.welma-education.app/api',
        auth0: {
          domain: 'welma-education-staging.eu.auth0.com',
          clientId: 'XSHAc8sUutfdXeTkC0u30MtjUxnqfpwk',
          audience: 'https://api.welma.app',
        },
        university: 'staging',
        features: {
          card: 'v3',
          certificate: 'v2',
        },
      };
    case 'enstp.lgs.education.welma.app':
    case 'lgs.enstp.td':
      return {
        apiURL: 'https://enstp.welma-education.app/api',
        auth0: {
          domain: 'enstp.eu.auth0.com',
          clientId: 'NPDtlN2JEvLCcyJwqXJcdDIpnMqTO9aJ',
          audience: 'https://enstp.api.education.welma.app',
        },
        university: 'enstp',
        features: {
          card: 'v3',
          certificate: 'v2',
        },
      };
    default:
      return {
        apiURL: '/api',
        auth0: {
          domain: 'welma-education-staging.eu.auth0.com',
          clientId: 'XSHAc8sUutfdXeTkC0u30MtjUxnqfpwk',
          audience: 'https://api.welma.app',
        },
        university: 'uvt',
        features: {
          card: 'v3',
          certificate: 'v2',
        },
      };
  }
}

const APPLICATION_CONFIGURATION = new InjectionToken<LgsConfiguration>('APPLICATION_CONFIGURATION');

const configs = {
  uvt: {
    university: {
      name: `Université Virtuelle du Tchad`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/v1703085470/logo-universite-virtuelle_i4ulxx.png',
      website: 'www.uvt.td',
      address: 'BP : 5711 N’Djaména - Tchad',
      city: `N'Djaména`,
      certificateSigner: {
        title: 'Le Vice-Président chargé des enseignements',
        name: 'Dr BATOUMA NARKOY',
        signature: '',
      },
      reportSigner: {
        title: 'Le Responsable de la Cellule Études, Planification et Scolarité',
        direction: 'Cellule Études, Planification et Scolarité',
      },
    },
    theme: {
      primary: '#1A78F4',
      accent: '#FBC700',
      hideName: true,
    },
  },
  undj: {
    university: {
      name: `Université de N'Djaména`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/logo-ndjamena_z9zede.png',
      website: 'www.universite-ndjamena.td',
      address: 'B.P: 1117 / Tél. (235) 22 51 54 54',
      city: `N'Djaména`,
      certificateSigner: {
        title: 'Directeur de la Scolarité et des Examens',
        name: 'Pr. Amir Moungache',
        signature:
          'https://res.cloudinary.com/doknvxtth/image/upload/v1703114755/signature-directeur-undj_t8jlr9.png',
      },
      reportSigner: {
        title: 'Le Chef du Service de la Scolarité et des Examens',
        direction: 'Service de la Scolarité et des Examens',
      },
    },
    theme: {
      primary: '#002569',
      accent: '#FFCE00',
      hideName: false,
    },
  },
  staging: {
    university: {
      name: `Université Welma`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/v1708868688/logo-512x512_d1qk85.png',
      website: 'www.welma.io',
      address: '21 Ouest Foire, Dakar - Sénégal',
      city: `Dakar`,
      certificateSigner: {
        title: 'Directeur de la Scolarité et des Examens',
        name: 'Pr. Ahmad Thiam',
        signature: '',
      },
      reportSigner: {
        title: 'Le Chef du Service de la Scolarité et des Examens',
        direction: 'Service de la Scolarité et des Examens',
      },
    },
    theme: {
      primary: '#2251CC',
      accent: '#B620E0',
      hideName: false,
    },
  },
  enstp: {
    university: {
      name: `École Nationale Supérieure des Travaux Publics`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/v1704708383/logo-enstp-square_y7e5f1.png',
      website: 'www.enstp.td',
      address: 'B.P: 60 / Tél. (235) 22 52 34 20',
      city: `N'Djaména`,
      certificateSigner: {
        title: 'Directeur des Études',
        name: 'HINAMARI SAVAISSEL',
        signature: '',
      },
      reportSigner: {
        title: 'Le Directeur Général',
        direction: 'Service de la Scolarité et des Examens',
      },
    },
    theme: {
      primary: '#2A3084',
      accent: '#EC752E',
      hideName: false,
    },
  },
} as const satisfies Record<string, Omit<LgsConfiguration, 'features'>>;

function getLgsConfiguration(): LgsConfiguration {
  const config = configs[environment.university];
  return {
    university: config.university,
    theme: config.theme,
    features: environment.features,
  };
}
